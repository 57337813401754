import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router/index'
import { Message } from 'element-ui'

Vue.use(Vuex)

export default new Vuex.Store({
  // 存放状态(全局状态数据)
  state: {
    tabList: [], //面包屑数据

    // 全局样式大小
    size: 'mini',
    // 超管
    list1: [
      {
        id: '1',
        icon: 'icon-YHGL iconfont m5',
        // path: '/Adminuser',
        name: '平台管理',
        childs: [
          {
            id: '1-1',
            icon: 'icon-user iconfont m5',
            path: '/Platform',
            name: '学员管理',
          },
          {
            id: '1-2',
            icon: 'icon-PTGL iconfont m5',
            path: '/Adminuser',
            name: '用户管理',
          },
          {
            id: '1-3',
            icon: 'icon-PTGL iconfont m5',
            path: '/Standing',
            name: '台账管理',
          },
        ],
      },
      {
        id: '2',
        icon: 'icon-GGGL iconfont m5',
        name: '公告管理',
        childs: [
          {
            id: '2-2',
            icon: 'icon-GGGL iconfont m5',
            path: '/Banner',
            name: '公告管理',
          },
        ],
      },
      {
        id: '3',
        icon: 'icon-PX iconfont m5',
        // path: '/Adminuser',
        name: '培训管理',
        childs: [
          {
            id: '3-1',
            icon: 'icon-STLX iconfont m5',
            path: '/Questionstype',
            name: '工种管理',
          },

          {
            id: '3-4',
            icon: 'icon-STGL iconfont m5',
            path: '/Questions',
            name: '试题管理',
          },
        ],
      },
      // 考务中心
      {
        id: '4',
        icon: 'icon-KSGL iconfont m5',
        path: '/Adminuser',
        name: '考务中心',
        childs: [
          {
            id: '4-0',
            icon: 'icon-BM iconfont m5',
            path: '/AddApply',
            name: '批次管理',
          },

          {
            id: '4-2',
            icon: 'icon-BM iconfont m5',
            path: '/Platforms',
            name: '批次试卷',
          },

          {
            id: '4-1',
            icon: 'icon-BM iconfont m5',
            path: '/Applyitem',
            name: '报名审批',
          },

          {
            id: '4-3',
            icon: 'icon-BMGL iconfont m5',
            path: '/Settlement',
            name: '报名结算',
          },
          {
            id: '4-4',
            icon: 'icon-ZKZCX iconfont m5',
            path: '/Docket',
            name: '准考证',
          },
          {
            id: '4-5',
            icon: 'icon-CJCX iconfont m5',
            path: '/Grade',
            name: '成绩查询',
          },
          {
            id: '4-6',
            icon: 'icon-ZSCX iconfont m5',
            path: '/Certificate',
            name: '证书查询',
          },
          {
            id: '4-7',
            icon: 'icon-QZJS iconfont m5',
            path: '/Closecertificate',
            name: '取证结算',
          },
        ],
      },
    ],
    // 上级管理部门
    list2: [
      {
        id: '1',
        icon: 'icon-YHGL iconfont m5',
        name: '平台管理',
        childs: [
          {
            id: '1-3',
            icon: 'icon-PTGL iconfont m5',
            path: '/Standing',
            name: '台账管理',
          },
        ],
      },
    ],
    //招生机构
    list3: [
      // 考务中心
      {
        id: '4',
        icon: 'icon-KSGL iconfont m5',
        name: '考务中心',
        childs: [
          {
            id: '4-0',
            icon: 'icon-BM iconfont m5',
            path: '/AddApply',
            name: '批次管理',
          },
          {
            id: '4-1',
            icon: 'icon-BM iconfont m5',
            path: '/Applyitem',
            name: '报名审批',
          },
        ],
      },
    ],
    //教务
    list4: [
      {
        id: '1',
        icon: 'icon-YHGL iconfont m5',
        name: '平台管理',
        childs: [
          {
            id: '1-2',
            icon: 'icon-PTGL iconfont m5',
            path: '/Adminuser',
            name: '用户管理',
          },
          {
            id: '1-3',
            icon: 'icon-PTGL iconfont m5',
            path: '/Standing',
            name: '台账管理',
          },
        ],
      },
      {
        id: '2',
        icon: 'icon-GGGL iconfont m5',
        name: '公告管理',
        childs: [
          {
            id: '2-2',
            icon: 'icon-GGGL iconfont m5',
            path: '/Banner',
            name: '公告管理',
          },
        ],
      },
      {
        id: '3',
        icon: 'icon-PX iconfont m5',
        name: '培训管理',
        childs: [
          {
            id: '3-1',
            icon: 'icon-STLX iconfont m5',
            path: '/Questionstype',
            name: '工种管理',
          },

          {
            id: '3-4',
            icon: 'icon-STGL iconfont m5',
            path: '/Questions',
            name: '试题管理',
          },
        ],
      },
      // 考务中心
      {
        id: '4',
        icon: 'icon-KSGL iconfont m5',
        name: '考务中心',
        childs: [
          {
            id: '4-0',
            icon: 'icon-BM iconfont m5',
            path: '/AddApply',
            name: '批次管理',
          },

          {
            id: '4-2',
            icon: 'icon-BM iconfont m5',
            path: '/Platforms',
            name: '批次试卷',
          },

          {
            id: '4-1',
            icon: 'icon-BM iconfont m5',
            path: '/Applyitem',
            name: '报名审批',
          },

          {
            id: '4-3',
            icon: 'icon-BMGL iconfont m5',
            path: '/Settlement',
            name: '报名结算',
          },
          {
            id: '4-4',
            icon: 'icon-ZKZCX iconfont m5',
            path: '/Docket',
            name: '准考证',
          },
          {
            id: '4-5',
            icon: 'icon-CJCX iconfont m5',
            path: '/Grade',
            name: '成绩查询',
          },
          {
            id: '4-6',
            icon: 'icon-ZSCX iconfont m5',
            path: '/Certificate',
            name: '证书查询',
          },
          {
            id: '4-7',
            icon: 'icon-QZJS iconfont m5',
            path: '/Closecertificate',
            name: '取证结算',
          },
        ],
      },
    ],
    // 业务员
    list5: [
      // 考务中心
      {
        id: '4',
        icon: 'icon-KSGL iconfont m5',
        name: '考务中心',
        childs: [
          {
            id: '4-1',
            icon: 'icon-BM iconfont m5',
            path: '/Applyitem',
            name: '报名审批',
          },
        ],
      },
    ],
    // 制卷人
    list6: [
      {
        id: '3',
        icon: 'icon-PX iconfont m5',
        name: '培训管理',
        childs: [
          {
            id: '3-4',
            icon: 'icon-STGL iconfont m5',
            path: '/Questions',
            name: '试题管理',
          },
        ],
      },
      // 考务中心
      {
        id: '4',
        icon: 'icon-KSGL iconfont m5',
        name: '考务中心',
        childs: [
          {
            id: '4-2',
            icon: 'icon-BM iconfont m5',
            path: '/Platforms',
            name: '批次试卷',
          },
        ],
      },
    ],
    // 审核员
    list7: [
      {
        id: '4',
        icon: 'icon-KSGL iconfont m5',
        name: '考务中心',
        childs: [
          {
            id: '4-1',
            icon: 'icon-BM iconfont m5',
            path: '/Applyitem',
            name: '报名审批',
          },

          {
            id: '4-3',
            icon: 'icon-BMGL iconfont m5',
            path: '/Settlement',
            name: '报名结算',
          },
        ],
      },
    ],

    // 全局颜色
    color: '#409EFF',

    // 是否显示logo
    isIcon: true,

    //控制菜单展开/收起
    isNav: false,

    // 是否固定头部
    isHeader: true,

    userlist: [],
  },
  // 获取 state 中的数据，类似于组件中的计算属性
  getters: {},

  // 通过 commit 方法通知vuex中的mutations方法来操作（同步）
  // 参数1：mutations中的定义的方法名
  // 参数2：payload数据  可选

  // 对于 state 成员进行同步修改操作（也可以支持异步操作）
  mutations: {
    selectMenu(state, val) {
      // 判断添加数据是否为首页
      if (
        val.name !== 'Home' &&
        val.name != '职业技能等级认定计划备案表' &&
        val.name != '职业技能等级认定工作实施方案' &&
        val.name != '考生情况说明' &&
        val.name != '试卷的交接记录' &&
        val.name != '考务会议记录' &&
        val.name != '考生违规违纪处理' &&
        val.name != '试卷袋' &&
        val.name != '考场情况记录表' &&
        val.name != '职业技能等级认定考务手册' &&
        val.name != '申报表' &&
        val.name != '技能人才评价现场考评反馈报告' &&
        val.name != '技能人才评价考评员诚信承诺书' &&
        val.name != '内部质量督导人员督考报告'
      ) {
        // 判断如果不存在
        const index = state.tabList.findIndex((item) => item.name === val.name)
        // 如果不存在
        if (index === -1) {
          state.tabList.push(val)
        }
        if (state.tabList.length >= 10) {
          state.tabList.splice(0, 1)
        }
      }
    },
    // 删除标签页
    deleteMenu(state, val) {
      let arr = state.tabList.filter((item) => val != item)
      state.tabList = arr
    },
    // 设置布局
    setsize(state, val) {
      state.size = val.size
      router.push({ path: '/Transfer' })
      setTimeout(() => {
        router.push(`${val.path}`)
        Message({ type: 'success', message: '切换成功', duration: '500' })
      }, 300)
    },

    // 设置颜色
    setcolor(state, val) {
      state.color = val.color
      router.push({ path: '/Transfer' })
      setTimeout(() => {
        router.push({ name: `${val.path}` })
        Message({ type: 'success', message: '切换成功', duration: '500' })
      }, 300)
    },
    // 设置导航栏
    setnav(state, val) {
      state.isNav = val
    },
    // 设置logo
    setIcon(state, val) {
      state.isIcon = val
    },
    setheader(state, val) {
      state.isHeader = val
    },
    reomvetabList(state, val) {
      state.tabList = []
    },
    setuserlist(state, val) {
      state.userlist = val
    },
  },

  // 进行异步操作，异步得到结果后通知 mutation 修改 state 成员
  actions: {},

  // 模块化状态管理，多状态文件管理时使用，开发项目时多为多模块项目
  modules: {},
})
