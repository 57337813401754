<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {

  data() {
    return {

    }
  },

}
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #fff;
}

.el-message--success {
  background-color: #f0f0f0 !important;
  padding: 15px 25px 15px 20px !important;
  min-width: 0 !important;
}

.el-message--info {
  background-color: #f0f0f0 !important;
  padding: 15px 25px 15px 20px !important;
  min-width: 0 !important;
}
</style>
