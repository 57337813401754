import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// axios
import axios from 'axios'
// icon
import './assets/font/iconfont.css'

// 地区选择器
import VDistpicker from 'v-distpicker'

import qs from 'qs'
Vue.prototype.$qs = qs

Vue.config.productionTip = false
//element组件库
Vue.use(ElementUI)

Vue.component('v-distpicker', VDistpicker)

Vue.prototype.axios = axios
axios.defaults.baseURL = ''

new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount('#app')
