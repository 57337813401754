import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Login',
  },
  {
    path: '/Login',
    name: '登录',
    component: () => import('../views/Login.vue'),
  },

  {
    path: '/Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/Transfer',
        component: () => import('../views/Transfer.vue'),
      },
      {
        path: '/Adminuser',
        meta: {
          name: '平台管理',
        },
        name: '用户管理',
        component: () => import('../views/Admin/Adminuser.vue'),
      },
      {
        path: '/Standing',
        meta: {
          name: '平台管理',
        },
        name: '台账管理',
        component: () => import('../views/Admin/Standing/base/index.vue'),
      },
      {
        path: '/Platform',
        meta: {
          name: '平台管理',
        },
        name: '学员管理',
        component: () => import('../views/Admin/Platform.vue'),
      },

      {
        path: '/Banner',
        meta: {
          name: '公告管理',
        },
        name: '公告管理',
        component: () => import('../views/Announcement/Banner.vue'),
      },

      {
        path: '/Setbanner',
        meta: {
          name: '公告管理',
        },
        name: '公告详情',
        component: () => import('../views/Announcement/Setbanner.vue'),
      },

      // 培训管理
      {
        path: '/Questionstype',
        meta: {
          name: '培训管理',
        },
        name: '工种管理',
        component: () => import('../views/Examination/Questionstype.vue'),
      },

      {
        path: '/Questions',
        meta: {
          name: '培训管理',
        },
        name: '试题管理',
        component: () => import('../views/Examination/Questions.vue'),
      },

      // 考务中心

      {
        path: '/AddApply',
        meta: {
          name: '考务中心',
        },
        name: '批次管理',
        component: () => import('../views/Attendance/AddApply/base/index.vue'),
      },

      {
        path: '/Platforms',
        meta: {
          name: '考务中心',
        },
        name: '批次试卷',
        component: () => import('../views/Attendance/Platforms/base/index.vue'),
      },

      {
        path: '/Applyitem',
        meta: {
          name: '考务中心',
        },
        name: '报名审批',
        component: () => import('../views/Attendance/Applyitem/base/index.vue'),
      },
      {
        // Settlement  报名结算
        path: '/Settlement',
        meta: {
          name: '考务中心',
        },
        name: '报名结算',
        component: () =>
          import('../views/Attendance/Docket/base/Settlement.vue'),
      },
      {
        // Docket   准考证
        path: '/Docket',
        meta: {
          name: '考务中心',
        },
        name: '准考证',
        component: () => import('../views/Attendance/Docket/base/Docket.vue'),
      },
      {
        // Grade   成绩查询
        path: '/Grade',
        meta: {
          name: '考务中心',
        },
        name: '成绩查询',
        component: () => import('../views/Attendance/Grade/base/index.vue'),
      },

      {
        // Certificate   证书查询
        path: '/Certificate',
        meta: {
          name: '考务中心',
        },
        name: '证书查询',
        component: () =>
          import('../views/Attendance/Docket/base/Certificate.vue'),
      },

      {
        // Closecertificate   取证结算
        path: '/Closecertificate',
        meta: {
          name: '考务中心',
        },
        name: '取证结算',
        component: () =>
          import('../views/Attendance/Closecertificate/base/index.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
})

export default router
